<template>
  <v-card min-height="194">
    <v-card-title class="pb-0">Receita</v-card-title>
    <v-card-subtitle v-if="hiddenValue" class="valor-receita">
      {{ receita | BrazilianCurrency }}
    </v-card-subtitle>
    <v-card-subtitle
      class="space-value2 valor-receita"
      style="magin: 0 auto !important"
      v-else
    >
    </v-card-subtitle>
    <v-row v-if="comissao || bonus">
      <v-col cols="12" md="6" lg="6" class="d-flex flex-column align-center">
        <div class="d-flex align-left">
          <v-card-subtitle class="pa-0 ml-5" style="font-size: 1rem">
            Comissão:
          </v-card-subtitle>
          <v-card-text
            v-if="hiddenValue"
            style="font-size: 1rem; font-weight: 600"
          >
            {{ comissao | BrazilianCurrency }}
          </v-card-text>
          <v-card-text class="space-value" v-else> </v-card-text>
        </div>
      </v-col>
      <v-col cols="12" md="6" lg="6" class="d-flex flex-column align-center">
        <div class="d-flex align-left">
          <v-card-subtitle class="pa-0" style="font-size: 1rem">
            Bônus:
          </v-card-subtitle>
          <v-card-text
            v-if="hiddenValue"
            style="font-size: 1rem; font-weight: 600"
          >
            {{ bonus | BrazilianCurrency }}
          </v-card-text>
          <v-card-text class="space-value" v-else> </v-card-text>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import currencyMixin from "@/mixins/currencyMixin";
export default {
  name: "CardComissao",

  mixins: [currencyMixin],

  props: {
    comissao: {
      type: [String, Number]
    },
    bonus: {
      type: [String, Number]
    },
    receita: {
      type: [String, Number]
    },
    hiddenValue: {
      type: Boolean
    }
  }
};
</script>

<style scoped>
.valor-receita {
  margin-top: 30px !important;
  margin-bottom: 10px !important;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.87) !important;
}

.space-value {
  background-color: #e4e4e4;
  width: 100px;
  height: 30px;
  margin-left: 10px;
}
.space-value2 {
  background-color: #e4e4e4;
  width: 100px;
  height: 30px;
  margin: 25px auto !important;
}
</style>
